
import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';


function NotFound() {  
  
  return (
    <div className="card">
      <div className="card-header">
        Page Not Found !!
      </div>
      <div className="card-body">
        <div className="App">
        Page Not Found !!
        </div></div>
    </div>
  );
}



export default NotFound;
